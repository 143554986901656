import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Skills from './Skills/Skills';
import Experience from './Experience/Experience';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';

import { PortfolioProvider } from '../context/context';

import {
  heroData,
  aboutData,
  skillsData,
  experienceData,
  contactData,
  footerData,
} from '../mock/data';

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [skills, setSkills] = useState([]);
  const [experience, setExperience] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});
  const [clicked, setClicked] = useState({ mode: false });

  const handleDarkMode = () => {
    setClicked(prevState => ({
      ...prevState,
      mode: !clicked.mode,
    }));

    const heroDark = document.querySelector('#hero');
    const aboutDark = document.querySelector('#about');
    const skillsDark = document.querySelector('#skills');
    const experienceDark = document.querySelector('#experience');
    const contactDark = document.querySelector('#contact');
    const footerDark = document.querySelector('.footer');
    const darkModeButton = document.querySelector('.dark-mode-button');
    const ctaHeroButton = document.querySelector('.cta-btn--hero');

    heroDark.classList.toggle('dark-mode');
    aboutDark.classList.toggle('dark-mode');
    skillsDark.classList.toggle('dark-mode');
    experienceDark.classList.toggle('dark-mode');
    contactDark.classList.toggle('dark-mode');
    footerDark.classList.toggle('dark-mode');
    darkModeButton.classList.toggle('dark-mode');
    ctaHeroButton.classList.toggle('dark-mode');
  };

  useEffect(() => {
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setSkills([...skillsData]);
    setExperience([...experienceData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, about, skills, experience, contact, footer }}>
      <Hero handleDarkMode={handleDarkMode} clicked={clicked} />
      <About />
      <Skills />
      <Experience />
      <Contact />
      <Footer />
    </PortfolioProvider>
  );
}

export default App;
