/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';

const Slider = ({ handleClick }) => {
  return (
    <div className="dark-mode-button">
      <label className="dark-mode-button-label">
        <input type="checkbox" onChange={handleClick} />
        <span className="slider round" />
      </label>
    </div>
  );
};

export default Slider;
