/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useRef, useContext } from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { media } from './styles/media';
import PortfolioContext from '../../context/context';
import sr from '../../utils/sr';
import { srConfig } from '../../config';
import Title from '../Title/Title';

const StyledTabButton = styled.button`
  display: inline-block;
  position: relative;
  padding: 0.8rem 1.6rem;
  font-weight: bold;
  line-height: 1;
  z-index: 1;
  width: 100%;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
  height: 42px;
  text-align: left;
  white-space: nowrap;
  font-size: 1.6rem;
  -webkit-text-fill-color: ${props => (props.isActive ? '#333333' : '#02aab0')};
  background-image: linear-gradient(135deg, #02aab0 0%, #00cdac 100%);
  -webkit-background-clip: text;
  border: 1px solid transparent;
  border-image-slice: 1;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 0px;
    height: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
    background-image: linear-gradient(135deg, #02aab0 0%, #00cdac 100%);
    ${media.phone`
      background-image: none;
    `}
  }

  &:hover {
    -webkit-text-fill-color: white;
    text-decoration: none;
    ${media.phone`
      -webkit-text-fill-color: #00cdac;
  `}

    &::after {
      width: 100%;
    }
  }

  &:focus {
    outline: none;
  }

  ${media.tabPort`padding: 0 15px 2px;`};
  ${media.phone`
    background-image: none;
    -webkit-text-fill-color: #00cdac;
    padding: 0 5px;
    white-space: normal;
    text-align: center;
    width: 130px;
    font-size: 15px;
  `};
`;

const StyledHighlight = styled.span`
  display: block;
  background: linear-gradient(135deg, #02aab0 0%, #00cdac 100%);
  width: 100%;
  height: 42px;
  border: 1px solid white;
  position: absolute;
  top: 0;
  left: 0;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
  transform: translateY(${props => (props.activeTabId > 0 ? props.activeTabId * 42 : 0)}px);
  ${media.tabPort`
    width: auto;
    // max-width: 120px;
    height: 10px;
    top: auto;
    bottom: 0;
    transform: translateX(
      ${props => (props.activeTabId > 0 ? props.activeTabId * 120 : 0)}px
    );
    margin-left: 50px;
  `};
  ${media.phone`
    margin-left: 40px;
    height: 5px;
    transform: translateX(
      ${props => (props.activeTabId > 0 ? props.activeTabId * 130 : 0)}px
    );
    width: 130px;
  `};
  ${media.phoneXs`
    margin-left: 25px;
  `};
`;

const Experience = () => {
  const { experience } = useContext(PortfolioContext);

  const [activeTabId, setActiveTabId] = useState(0);
  const tabs = useRef([]);

  const revealContainer = useRef(null);

  if (typeof window !== 'undefined') {
    useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);
  }

  const windowUse = typeof window !== 'undefined' && window;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (windowUse.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="experience">
      <Container ref={revealContainer}>
        <div className="experience-wrapper">
          <Title title="Where I've Been" />
          <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
            <div className="experience-wrapper__text">
              <ul className="experience-wrapper__text-list" role="tabList" aria-label="Job tabs">
                {experience &&
                  experience.map((item, i) => {
                    const { company } = item;
                    return (
                      <li key={i}>
                        <StyledTabButton
                          isActive={activeTabId === i}
                          onClick={() => setActiveTabId(i)}
                          ref={el => (tabs.current[i] = el)}
                          id={`tab-${i}`}
                          role="tab"
                          aria-selected={activeTabId === i}
                          aria-controls={`panel-${i}`}
                          tabIndex={activeTabId === i ? '0' : '-1'}
                        >
                          <span>{company}</span>
                        </StyledTabButton>
                      </li>
                    );
                  })}
                <StyledHighlight activeTabId={activeTabId} />
              </ul>

              {experience &&
                experience.map((item, i) => {
                  // const { frontmatter, html } = node;
                  const { title, company, location, range, detailsOne, detailsTwo, url } = item;
                  return (
                    <div
                      className="experience-wrapper__text-content"
                      key={i}
                      isActive={activeTabId === i}
                      id={`panel-${i}`}
                      role="tabpanel"
                      aria-labelledby={`tab-${i}`}
                      tabIndex={activeTabId === i ? '0' : '-1'}
                      hidden={activeTabId !== i}
                    >
                      <h4 className="experience-wrapper__text-title">
                        <span>{title}</span>
                        <br />
                        <span className="experience-wrapper__text-company">
                          <a href={url} target="_blank" rel="nofollow noopener noreferrer">
                            &nbsp;@&nbsp;{company}
                          </a>
                        </span>
                      </h4>
                      <h5 className="experience-wrapper__text-details">
                        <span>{location}, </span>
                        <span>{range}</span>
                        <hr />
                        <ul className="experience-wrapper__text-details-list">
                          <li>{detailsOne}</li>
                          <li>{detailsTwo}</li>
                        </ul>
                      </h5>
                      {/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
                    </div>
                  );
                })}
            </div>
          </Fade>
        </div>
      </Container>
    </section>
  );
};

export default Experience;
